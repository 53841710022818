import { AxiosError, AxiosResponse } from "axios"

import api from "../../services/api.service"
import { IResponseCommon } from "../common.type"
import { IResponseFinalExaminationArchive, IResponseFinalExaminationJobs, IResponseFinalExaminationListeners, IResponseFinalExaminationListenersCard } from "./finalExamination.type"


const route = '/finalExamination'


/** Получение списка итоговых работ */
export const getFinalExaminationListeners = async (pageNumber: number, search?: string) => {
    let response: IResponseFinalExaminationListeners

    try {
        const res: AxiosResponse<IResponseFinalExaminationListeners, AxiosError> = await api.get(`${route}/getFinalExaminationList`, {params: {pageNumber, search}})

        if (res.statusText !== 'ERR_NETWORK') {
            response = res.data
        } else {
            response = {
                status: 500, 
                message: 'Сервер временно не доступен.',
                errors: null
            }
        }

        return response
    } catch(error) {
        if ((error as AxiosError).code === 'ERR_NETWORK') {response = {status: 500, message: 'Сервер не доступен', errors: (error as AxiosError).code}; return response}

        const resError = (error as AxiosError).response?.data as IResponseFinalExaminationListeners
        response = {status: 500, message: resError?.message, errors: resError.errors}
        return response
    }
}
/** Получение списка итоговых работ */
export const getFinalExaminationJobs = async () => {
    let response: IResponseFinalExaminationJobs

    try {
        const res: AxiosResponse<IResponseFinalExaminationJobs, AxiosError> = await api.get(`${route}/getFinalExaminationJobs`)

        if (res.statusText !== 'ERR_NETWORK') {
            response = res.data
        } else {
            response = {
                status: 500, 
                message: 'Сервер временно не доступен.',
                errors: null
            }
        }

        return response
    } catch(error) {
        if ((error as AxiosError).code === 'ERR_NETWORK') {response = {status: 500, message: 'Сервер не доступен', errors: (error as AxiosError).code}; return response}

        const resError = (error as AxiosError).response?.data as IResponseFinalExaminationJobs
        response = {status: 500, message: resError?.message, errors: resError.errors}
        return response
    }
}
/** Получение списка итоговых работ из архива */
export const getFinalExaminationArchive = async (pageNumber: number, search?: string, isArchive: number = 1) => {
    let response: IResponseFinalExaminationArchive

    try {
        const res: AxiosResponse<IResponseFinalExaminationArchive, AxiosError> = await api.get(`${route}/getFinalExaminationList`, {params: {pageNumber, search, isArchive}})

        if (res.statusText !== 'ERR_NETWORK') {
            response = res.data
        } else {
            response = {
                status: 500, 
                message: 'Сервер временно не доступен.',
                errors: null
            }
        }

        return response
    } catch(error) {
        if ((error as AxiosError).code === 'ERR_NETWORK') {response = {status: 500, message: 'Сервер не доступен', errors: (error as AxiosError).code}; return response}

        const resError = (error as AxiosError).response?.data as IResponseFinalExaminationArchive
        response = {status: 500, message: resError?.message, errors: resError.errors}
        return response
    }
}



/** Получение данных по анкете итоговой аттестации */
export const getFinalExaminationCard = async (finalExaminationID: number) => {
    let response: IResponseFinalExaminationListenersCard

    try {
        const res: AxiosResponse<IResponseFinalExaminationListenersCard, AxiosError> = await api.get(`${route}/getFinalExaminationCard`, {params: {finalExaminationID}})

        if (res.statusText !== 'ERR_NETWORK') {
            response = res.data
        } else {
            response = {
                status: 500,
                message: 'Сервер временно не доступен.',
                errors: null
            }
        }

        return response
    } catch(error) {
        if ((error as AxiosError).code === 'ERR_NETWORK') {response = {status: 500, message: 'Сервер не доступен', errors: (error as AxiosError).code}; return response}

        const resError = (error as AxiosError).response?.data as IResponseFinalExaminationListenersCard
        response = {status: 500, message: resError?.message, errors: resError.errors}
        return response
    }
}
/** Сохранение данных по анкете слушателя */
export const setFinalExaminationCardSave = async (finalExaminationID: number, themeIAR: string, iarScore: string, testScore: string) => {
    let response: IResponseCommon

    try {
        const res: AxiosResponse<IResponseCommon, AxiosError> = await api.put(`${route}/setFinalExaminationCardSave?finalExaminationID=${finalExaminationID}&themeIAR=${themeIAR}&iarScore=${iarScore}&testScore=${testScore}`)

        if (res.statusText !== 'ERR_NETWORK') {
            response = res.data
        } else {
            response = {
                status: 500,
                message: 'Сервер временно не доступен.',
                errors: null
            }
        }

        return response
    } catch(error) {
        if ((error as AxiosError).code === 'ERR_NETWORK') {response = {status: 500, message: 'Сервер не доступен', errors: (error as AxiosError).code}; return response}

        const resError = (error as AxiosError).response?.data as IResponseCommon
        response = {status: 500, message: resError?.message, errors: resError.errors}
        return response
    }
}



/** Отправка итоговой работы слушателя на печать */
export const setFinalExaminationToPrint = async (finalExaminationID: number) => {
    let response: IResponseCommon

    try {
        const res: AxiosResponse<IResponseCommon, AxiosError> = await api.put(`${route}/setFinalExaminationToPrint?finalExaminationID=${finalExaminationID}`)
        
        if (res.statusText !== 'ERR_NETWORK') {
            response = res.data
        } else {
            response = {
                status: 500,
                message: 'Сервер временно не доступен.',
                errors: null
            }
        }

        return response
    } catch(error) {
        if ((error as AxiosError).code === 'ERR_NETWORK') {response = {status: 500, message: 'Сервер не доступен', errors: (error as AxiosError).code}; return response}

        const resError = (error as AxiosError).response?.data as IResponseCommon

        response = {status: 500, message: resError?.message, errors: resError.errors}
        return response
    }
}
/** Перемещение итоговой работы слушателя в архив */
export const setFinalExaminationInArchive = async (finalExaminationID: number) => {
    let response: IResponseCommon

    try {
        const res: AxiosResponse<IResponseCommon, AxiosError> = await api.put(`${route}/setFinalExaminationInArchive?finalExaminationID=${finalExaminationID}`)

        if (res.statusText !== 'ERR_NETWORK') {
            response = res.data
        } else {
            response = {
                status: 500,
                message: 'Сервер временно не доступен.',
                errors: null
            }
        }

        return response
    } catch(error) {
        if ((error as AxiosError).code === 'ERR_NETWORK') {response = {status: 500, message: 'Сервер не доступен', errors: (error as AxiosError).code}; return response}

        const resError = (error as AxiosError).response?.data as IResponseCommon
        response = {status: 500, message: resError?.message, errors: resError.errors}
        return response
    }
}
/** Перемещение анкеты итоговой работы слушателя обратно на обработку */
export const setFinalExaminationWithArchive = async (finalExaminationID: number) => {
    let response: IResponseCommon

    try {
        const res: AxiosResponse<IResponseCommon, AxiosError> = await api.put(`${route}/setFinalExaminationWithArchive?finalExaminationID=${finalExaminationID}`)

        if (res.statusText !== 'ERR_NETWORK') {
            response = res.data
        } else {
            response = {
                status: 500,
                message: 'Сервер временно не доступен.',
                errors: null
            }
        }

        return response
    } catch(error) {
        if ((error as AxiosError).code === 'ERR_NETWORK') {response = {status: 500, message: 'Сервер не доступен', errors: (error as AxiosError).code}; return response}

        const resError = (error as AxiosError).response?.data as IResponseCommon
        response = {status: 500, message: resError?.message, errors: resError.errors}
        return response
    }
}