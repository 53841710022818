import { useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useShallow } from 'zustand/react/shallow'
import moment from 'moment'

import { useInput } from 'hooks/useInput'
import { useDebounce } from 'hooks/useDebounce'

import { Applications } from './_interface'
import { ApplicationsFilters } from './_filters'
import { IApplicationStatusesForFilter, IApplications, IApplicationsModalFilter } from './_types'

import { CardModeType } from 'components/smart/common.type'
import { SelectMailingContainer, ISelectMailingContainer } from 'components/smart/selectMailings'
import { CardApplicationContainer, ICardApplicationContainer } from 'components/smart/cardApplication'
import { ApplicationsPromoContainer, IApplicationsPromoContainer } from 'components/smart/applicationsPromo' 

import { useDict } from 'store/dict/dict.state'
import { useLogin } from 'store/login/login.state'
import { useContracts } from 'store/contracts/contracts.state'
import { useApplications } from 'store/applications/applications.state'
import { IApplicationsFilter } from 'store/applications/applications.types'

import { IModalConfirm, ModalConfirm } from 'components/dumb/modal.confirm'
import { IModalCalendar, ModalCalendar } from 'components/dumb/modal.calendar'

import { NotificationContext, notifyOpen } from 'components/ui/notification/notification.provider'


/** Заявки - Контейнер */
export const ApplicationsContainer = () => {
    const navigate = useNavigate()
    const notify = useContext(NotificationContext)

    const accessLevelRead = useInput<boolean>(false)

    const dictState = useDict(useShallow((state) => state))
    const {accessLevelID} = useLogin(useShallow((state) => state.profile))
    const applicationsState = useApplications(useShallow((state) => state))
    const {createContract} = useContracts(useShallow((state) => ({createContract: state.actionSetContractCard})))

    const modalConfirm = useInput<IModalConfirm>({isOpen: false})
    const modalCalendar = useInput<IModalCalendar>({isOpen: false})
    const modalMailings = useInput<ISelectMailingContainer>({isOpen: false})
    const cardApplications = useInput<ICardApplicationContainer>({isOpen: false})
    const cardApplicationsPromo = useInput<IApplicationsPromoContainer>({isOpen: false})

    const txtSearch = useInput<string>('')
    const currentPageNumber = useInput<number>(1)

    const filterIsOpen = useInput<boolean>(false)
    const filterIsActivate = useInput<boolean>(false)
    const filterApplicationStatuses = useInput<IApplicationStatusesForFilter[]>([])
    const filterDateNextCallFrom = useInput<string>('')
    const filterDateNextCallBefore = useInput<string>('')
    const filterDateStartEducationFrom = useInput<string>('')
    const filterDateStartEducationBefore = useInput<string>('')
    const filterDateCreateFrom = useInput<string>('')
    const filterDateCreateBefore = useInput<string>('')


    useEffect(() => {
        dictState.actionGetApplicationStatuses().then((res) => {
            filterApplicationStatuses.setValue(
                res.map((item) => ({
                    id: item.applicationStatusID,
                    name: item.applicationStatusName,
                    isSelected: false
                }))
            )    
        })

        return (() => {
            applicationsState.clearApplicationsState()

            currentPageNumber.setValue(1)
            txtSearch.setValue('')
        
            filterIsOpen.setValue(false)
            filterIsActivate.setValue(false)
            filterApplicationStatuses.setValue([])
            filterDateNextCallFrom.setValue('')
            filterDateNextCallBefore.setValue('')
            filterDateStartEducationFrom.setValue('')
            filterDateStartEducationBefore.setValue('')
            filterDateCreateFrom.setValue('')
            filterDateCreateBefore.setValue('')
        })
    }, []) // eslint-disable-line react-hooks/exhaustive-deps
   
    useEffect(() => {
        if (applicationsState.error) return notify && notifyOpen(applicationsState.error?.message, applicationsState.error?.type, 3000, notify)
    }, [applicationsState.error]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        loadDataApplications(txtSearch.value)
    }, [currentPageNumber.value, applicationsState.isReloadApplications]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        applicationsState.isReloadApplications && loadDataApplications(txtSearch.value)
    }, [applicationsState.isReloadApplications]) // eslint-disable-line react-hooks/exhaustive-deps


    /** Функция по получению списка заявок */
    const loadDataApplications = (search?: string) => {
        let applicationStatusesListSelected: string = ''

        if (filterApplicationStatuses.value.length > 0) {
            filterApplicationStatuses.value.forEach((item) => {
                if (item.isSelected) {
                    applicationStatusesListSelected += `'${item.name}',`
                }
            })
        }

        const filterData: IApplicationsFilter = {
            applicationStatuses: applicationStatusesListSelected,
            dateNextCallFrom: filterDateNextCallFrom.value,
            dateNextCallBefore: filterDateNextCallBefore.value,
            dateStartEducationFrom: filterDateStartEducationFrom.value,
            dateStartEducationBefore: filterDateStartEducationBefore.value,
            dateCreateFrom: filterDateCreateFrom.value,
            dateCreateBefore: filterDateCreateBefore.value,
        }

        applicationsState.actionGetApplications(currentPageNumber.value, search || '', filterData).then((res) => {
            if (Number(res) !== 403) accessLevelRead.setValue(true)
        })
    }

    /** Кнопка применить (очистить) в окне - Фильтр по заявкам */
    const handleApplyClearFilter = (filterType: 'apply' | 'clear') => {
        switch (filterType) {
            case 'apply': {
                filterIsActivate.setValue(true)
                loadDataApplications(txtSearch.value)
                break
            }

            case 'clear': {
                filterIsActivate.setValue(false)
                filterApplicationStatuses.setValue(filterApplicationStatuses.value.map(status => ({...status, isSelected: false})))
                filterDateNextCallFrom.setValue('')
                filterDateNextCallBefore.setValue('')
                filterDateStartEducationFrom.setValue('')
                filterDateStartEducationBefore.setValue('')
                filterDateCreateFrom.setValue('')
                filterDateCreateBefore.setValue('')

                const filterData: IApplicationsFilter = {
                    applicationStatuses: '',
                    dateNextCallFrom: '', dateNextCallBefore: '',
                    dateStartEducationFrom: '', dateStartEducationBefore: '',
                    dateCreateFrom: '', dateCreateBefore: '',
                }
        
                applicationsState.actionGetApplications(currentPageNumber.value, txtSearch.value, filterData)
                break
            }
        }
    }

    /** Открытие/закрытие окна выбора даты в календаре */
    const handleOpenModalCalendar = (isOpen: boolean, fieldName: string) => {
        modalCalendar.setValue({
            isOpen,
            handlerButtonCancel() { modalCalendar.setValue({ isOpen: false }) },
            handlerButtonOk(stringDate) {
                switch (fieldName) {
                    case 'dateNextCallFrom': filterDateNextCallFrom.setValue(stringDate); break
                    case 'dateNextCallBefore': filterDateNextCallBefore.setValue(stringDate); break
                    
                    case 'dateStartEducationFrom': filterDateStartEducationFrom.setValue(stringDate); break
                    case 'dateStartEducationBefore': filterDateStartEducationBefore.setValue(stringDate); break
                    
                    case 'dateCreateFrom': filterDateCreateFrom.setValue(stringDate); break
                    case 'dateCreateBefore': filterDateCreateBefore.setValue(stringDate); break
                }

                modalCalendar.setValue({ isOpen: false })
            }
        })
    }

    /** Открытие/закрытие окна выбора рассылок в telegram */
    const handleOpenModalMailings = (isOpen: boolean, applicationID: number) => {
        modalMailings.setValue({
            isOpen, applicationID,
            handlerButtonCancel() { modalMailings.setValue({ isOpen: false }) },
        })
    }
    
    /** Открытие/закрытие анкеты заявки */
    const handleOpenCardApplication = (isOpen: boolean, cardMode: CardModeType, cardID?: number) => {
        cardApplications.setValue({
            isOpen, cardMode, cardID,
            handlerButtonCancel() {cardApplications.setValue({isOpen: false})}, 
            handlerButtonOk() {
                cardApplications.setValue({isOpen: false})
                loadDataApplications(txtSearch.value)
            }
        })
    }

    /** Открытие/закрытие списка заявок промо */
    const handleOpenCardApplicationPromo = (isOpen: boolean) => {
        cardApplicationsPromo.setValue({
            isOpen, 
            handlerButtonCancel() {cardApplicationsPromo.setValue({isOpen: false})}, 
            handlerButtonOk() {
                cardApplicationsPromo.setValue({isOpen: false})
                loadDataApplications(txtSearch.value)
            }
        })
    }

    /** Открытие/закрытие окна подтверждения */
    const handlerOpenModalConfirm = (isOpen: boolean, headerText: string, contentText: string, applicationID: number, dataBaseName: string) => {
        modalConfirm.setValue({
            isOpen, headerText, contentText, 
            handlerButtonCancel() {modalConfirm.setValue({isOpen: false})}, 
            handlerButtonOk() {
                createContract(0, {
                    actionForm: 'new', contractTypeID: 1, contractViewID: 1, contractStatusID: 1,
                    contractDate: (moment(new Date())).format('DD.MM.YYYY'), applicationID: Number(applicationID), dataBaseName: dataBaseName,
                    companyID: 0, companyContactFIO: '', companyContactPhone: '', companyContactEmail: '',
                    deliveryToWhom: '', deliveryAddress: '', comment: '', isPostPayment: 0, isLearningOutsideMoodle: 0
                }).then((res) => {
                    if (res === 200) {
                        modalConfirm.setValue({isOpen: false})
                        notify && notifyOpen('Данные успешно сохранены.', 'success', 1500, notify)

                        navigate('/lk/contracts')
                    }
                })
            }
        })
    }



    const debounceSearch = useDebounce(loadDataApplications, 500) // Запуск загрузки заявок через полсекунды
    const txtSearchOnChange = (value: string) => {txtSearch.setValue(value); debounceSearch(value)} // Поле поиска
    

    /** Свойства передаваемые в компоненту */
    const propsToComponent: IApplications = {
        accessLevelRead,

        accessLevelID, txtSearch, txtSearchOnChange, loadDataApplications,

        filterIsActivate, filterIsOpen,
        
        
        handleApplyClearFilter,
        handlerOpenModalConfirm,
        handleOpenModalCalendar,
        handleOpenModalMailings,
        handleOpenCardApplication,
        handleOpenCardApplicationPromo, 

        applicationsStatistics: applicationsState.applicationsStatistics,
        applicationsList: applicationsState.applications.items,

        currentPageNumber, isLoading: applicationsState.applications.isLoading,
        totalCount: applicationsState.applications.totalCount, 
        pageSize: applicationsState.applications.pageSize,
    }

    /** Свойства передаваемые в фильтр */
    const propsToFilter: IApplicationsModalFilter = {
        isOpen: filterIsOpen.value, 
        
        handleOpenModalCalendar: handleOpenModalCalendar,
        handlerButtonCancel: () => filterIsOpen.setValue(false),
        
        filterApplicationStatuses: filterApplicationStatuses, 
        handleApplyClearFilter: handleApplyClearFilter,
        
        filterDateNextCallFrom: filterDateNextCallFrom, 
        filterDateNextCallBefore: filterDateNextCallBefore,
        filterDateStartEducationFrom: filterDateStartEducationFrom, 
        filterDateStartEducationBefore: filterDateStartEducationBefore,
        filterDateCreateFrom: filterDateCreateFrom, 
        filterDateCreateBefore: filterDateCreateBefore,
        
    }

    return <>
        <Applications {...propsToComponent} />
        <ApplicationsFilters {...propsToFilter} />

        <ModalConfirm {...modalConfirm.value} />
        <ModalCalendar {...modalCalendar.value} />
        
        <SelectMailingContainer {...modalMailings.value} />
        <CardApplicationContainer {...cardApplications.value} />
        <ApplicationsPromoContainer {...cardApplicationsPromo.value} />

        
    </>
}