import { useContext, useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { useShallow } from 'zustand/react/shallow'

import { IMain } from './_types'
import { Main } from './_interface'

import { useInput } from 'hooks/useInput'

import { useLogin } from 'store/login/login.state'
import { useCommon } from 'store/common/common.state'
import { useFinalExamination } from 'store/finalExamination/finalExamination.state'


import { Loader } from 'components/ui/loader'
import { IDrawerMenu } from 'components/dumb/drawer.menu'
import { IModalConfirm } from 'components/dumb/modal.confirm'

import { NotificationContext, notifyOpen } from 'components/ui/notification/notification.provider'


/** Главная навигационная страница - Контейнер */
export const MainContainer = () => {
    const navigate = useNavigate()
    const notify = useContext(NotificationContext)

    const {setCountUnverifiedWorks, setCountNewPrintDiplom} = useFinalExamination(useShallow((state) => ({
        setCountUnverifiedWorks: state.actionSetCountUnverifiedWorks,
        setCountNewPrintDiplom: state.actionSetCountNewPrintDiplom
    })))

    const {actionGetCountInfoApp} = useCommon(useShallow((state) => ({
        actionGetCountInfoApp: state.actionGetCountInfoApp
    })))

    const countNewApplications = useInput<number | ''>('')
    const countNewContracts = useInput<number | ''>('')
    const countAccessContracts = useInput<number | ''>('')
    const countUnverifiedWorks = useInput<number | ''>('')
    const countNewPrintDiplom = useInput<number | ''>('')
    const countDeliveryAddress = useInput<number | ''>('')
    const countDeliveryFinished = useInput<number | ''>('')


    const {isAuthorized, isLoading, error, actionCheckAuth, actionLogout, actionGetUserProfile} = useLogin(useShallow((state) => ({
        isAuthorized: state.isAuthorized,
        isLoading: state.isLoading,
        error: state.error,
        actionCheckAuth: state.actionCheckAuth,
        actionLogout: state.actionLogout,
        actionGetUserProfile: state.actionGetUserProfile,
    })))

    const modalConfirm = useInput<IModalConfirm>({isOpen: false})
    const drawerMenu = useInput<IDrawerMenu>({isOpen: false})

    // Используем useRef для хранения идентификатора интервала
    const intervalRef = useRef<NodeJS.Timeout | null>(null)

    useEffect(() => {
        if (localStorage.getItem('us_id') && localStorage.getItem('us_exp')) {
            actionCheckAuth().then((res) => {
                if (!res) {
                    actionLogout()
                    navigate('/')
                } else {
                    actionGetUserProfile()

                    // Функция для выполнения запроса и обновления состояния
                    const fetchCountInfo = () => {
                        actionGetCountInfoApp().then((res) => {
                            countNewApplications.setValue(res.countNewApplications || 0)
                            countNewContracts.setValue(res.countNewContracts || 0)
                            countAccessContracts.setValue(res.countAccessContracts || 0)
                            countUnverifiedWorks.setValue(res.countUnverifiedWorks || 0)
                            countNewPrintDiplom.setValue(res.countNewPrintDiplom || 0)
                            countDeliveryAddress.setValue(res.countDeliveryAddress || 0)
                            countDeliveryFinished.setValue(res.countDeliveryFinished || 0)

                            setCountUnverifiedWorks(res.countUnverifiedWorks || 0)
                            setCountNewPrintDiplom(res.countNewPrintDiplom || 0)
                        })
                    };

                    // Выполняем запрос сразу при монтировании
                    fetchCountInfo()

                    // Проверяем, существует ли уже интервал
                    if (!intervalRef.current) {
                        // Устанавливаем интервал для выполнения запроса каждые 20 секунд
                        intervalRef.current = setInterval(fetchCountInfo, 20000)
                    }
                }
            })
        } else {
            actionLogout()
            navigate('/')
        }

        // Очищаем интервал при размонтировании компонента
        return () => {
            if (intervalRef.current) {
                clearInterval(intervalRef.current)
                intervalRef.current = null // Сбрасываем значение
            }
        }
    }, [isAuthorized]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (error) return notify && notifyOpen(error?.message, error?.type, 2000, notify)
    }, [error]) // eslint-disable-line react-hooks/exhaustive-deps


    /** Открытие/закрытие окна подтверждения */
    const handlerOpenModalConfirm = (isOpen: boolean, headerText: string, contentText: string) => {
        modalConfirm.setValue({
            isOpen, headerText, contentText, 
            handlerButtonCancel() {modalConfirm.setValue({isOpen: false})}, 
            handlerButtonOk() {
                actionLogout()
                navigate('/')
            }
        })
    }

    /** Открытие/закрытие главного мобильного меню */
    const handlerOpenDrawerMenu = (isOpen: boolean) => {
        drawerMenu.setValue({
            isOpen, 
            handlerButtonCancel() {drawerMenu.setValue({isOpen: false})}, 
            handlerOpenModalExitProgram() {
                handlerOpenModalConfirm(true, 'Выход из системы...', 'Вы действительно хотите выйти из приложения?')
            }
        })
    }

    const propsToComponent: IMain = {
        modalConfirm, handlerOpenModalConfirm,
        drawerMenu, handlerOpenDrawerMenu,

        countNewApplications, countNewContracts, countAccessContracts, countUnverifiedWorks,
        countNewPrintDiplom, countDeliveryAddress, countDeliveryFinished,
    }

    return <>{ isLoading ? <Loader isOpen={isLoading} color='#F34E2E' title='Загрузка...' /> : isAuthorized && <Main {...propsToComponent} /> }</>
}