import { useContext, useEffect } from "react"

import { Finality } from "./_interface"

import { NotificationContext, notifyOpen } from "components/ui/notification/notification.provider"


/** Контейнер страницы - Доставка */
export const FinalityContainer = () => {
    const notify = useContext(NotificationContext)


    return <>
        <Finality />
    </>
}