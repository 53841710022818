import { useContext, useEffect } from 'react'
import { useShallow } from 'zustand/react/shallow'

import { IListeners } from './_types'
import { Listeners } from './_interface'

import { useInput } from 'hooks/useInput'
import { useDebounce } from 'hooks/useDebounce'
import { useListeners } from 'store/listeners/listeners.state'

import { CardModeType } from 'components/smart/common.type'
import { ICardListenerContainer } from 'components/smart/cardListener'

import { NotificationContext, notifyOpen } from 'components/ui/notification/notification.provider'


/** Слушатели - Контейнер */
export const ListenersContainer = () => {
    const notify = useContext(NotificationContext)

    const listenersState = useListeners(useShallow((state) => state))
    const cardListener = useInput<ICardListenerContainer>({isOpen: false})

    const currentPageNumber = useInput<number>(1)
    const txtSearch = useInput<string>('')


    useEffect(() => {
        return (() => {
            listenersState.clearListenersState()

            currentPageNumber.setValue(1)
            txtSearch.setValue('')        
        })
    }, []) // eslint-disable-line react-hooks/exhaustive-deps
    
    useEffect(() => {
        if (listenersState.error) return notify && notifyOpen(listenersState.error?.message, listenersState.error?.type, 3000, notify)
    }, [listenersState.error]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        loadDataListeners(txtSearch.value)
    }, [currentPageNumber.value]) // eslint-disable-line react-hooks/exhaustive-deps
    useEffect(() => {
        listenersState.isReloadListeners && loadDataListeners(txtSearch.value)
    }, [listenersState.isReloadListeners]) // eslint-disable-line react-hooks/exhaustive-deps

    /** Функция по получению списка слушателей */
    const loadDataListeners = (search?: string) => {
        listenersState.actionGetListeners(currentPageNumber.value, search || '')
    }

    /** Открытие/закрытие карточки по слушателю */
    const handleOpenCardListener = (isOpen: boolean, cardMode: CardModeType, cardID?: number) => {
        cardListener.setValue({
            isOpen, cardMode, cardID,
            handlerButtonCancel() {cardListener.setValue({isOpen: false})}, 
            handlerButtonOk() {
                cardListener.setValue({isOpen: false})
                loadDataListeners(txtSearch.value)
            }
        })
    }

    const debounceSearch = useDebounce(loadDataListeners, 500) // Запуск загрузки заявок через полсекунды
    const txtSearchOnChange = (value: string) => {txtSearch.setValue(value); debounceSearch(value)} // Поле поиска

    /** Свойства передаваемые в компоненту */
    const propsToComponent: IListeners = {
        isLoading: listenersState.listeners.isLoading,
        txtSearch, txtSearchOnChange, loadDataListeners,

        currentPageNumber, 
        totalCount: listenersState.listeners.totalCount, 
        pageSize: listenersState.listeners.pageSize,

        listenersList: listenersState.listeners.items,

        cardListener, handleOpenCardListener, 
    }
    
    return <Listeners {...propsToComponent} />
}