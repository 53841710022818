import {create} from 'zustand'
import {devtools} from 'zustand/middleware'

import { getEducationStartEndDate, getCountInfoApp } from './common.api'
import { ICommonState, ICommonActions, IEduDateStartEnd, IInfoBadgeCRM } from './common.types'


export const initialStateCommon: ICommonState = {
    error: null,
    isLoading: false,
}

/** Хранилище страницы - Авторизация */
export const useCommon = create<ICommonState & ICommonActions>()(devtools((set) => ({
    ...initialStateCommon,


    clearCommonState: () => {set(initialStateCommon)},

    /** Имена баз данных сайтов */
    actionGetCountInfoApp: async () => {
        set({ isLoading: true })

        let returnData: IInfoBadgeCRM = {
            countNewApplications: 0,
            countNewContracts: 0,
            countAccessContracts: 0,
            countUnverifiedWorks: 0,
            countNewPrintDiplom: 0,
            countDeliveryAddress: 0,
            countDeliveryFinished: 0
        }

        const res = await getCountInfoApp()
        if (res.status !== 200) set({ error: { type: res.status === 500 ? 'error' : 'warning', message: res.message } })

        if (res.message === 'OK' && res.data) {
            set({ error: null })
            returnData = res.data
        }

        set({ isLoading: false })

        return returnData
    },
    
    /** Имена баз данных сайтов */
    actionGetEduDateStartEnd: async (startDate?: string, countMonthOrWeek?: number, isWeek?: number) => {
        set({isLoading: true})

        let returnData: IEduDateStartEnd = {
            eduStartDate: '',
            eduEndDate: ''
        }

        const res = await getEducationStartEndDate(startDate, countMonthOrWeek, isWeek)
        if (res.status !== 200) set({ error: {type: res.status === 500 ? 'error' : 'warning', message: res.message} })

        if (res.message === 'OK' && res.data) {
            set({error: null})
            returnData = res.data
        }

        set({isLoading: false})
        
        return returnData
    },
}), {name: 'commonState'}))