import { Navigate } from 'react-router-dom'
import { Route, Routes } from 'react-router'

import { ApplicationsContainer } from 'pages/applications'
import { CompaniesContainer } from 'pages/companies'
import { ListenersContainer } from 'pages/listeners'
import { ContractsContainer } from 'pages/contracts'
import { FinalExaminationContainer } from 'pages/finalExamination'
import { DeliveryContainer } from 'pages/delivery'
import { FinalityContainer } from 'pages/finality'

import { Page404 } from '../pages/page404'


/** Маршруты для главной страницы */
const MainRoutes = () => {
    return (
        <Routes>
            <Route path='/' element={<Navigate to='applications' />} />
            <Route path='applications' element={<ApplicationsContainer />} />
            {/* <Route path='commercialOffers' element={<CommercialOffersContainer />} /> */}

            <Route path='companies' element={<CompaniesContainer />} />
            <Route path='listeners' element={<ListenersContainer />} />
            <Route path='contracts' element={<ContractsContainer />} />

            <Route path='finalExamination' element={<FinalExaminationContainer />} />
            <Route path='delivery' element={<DeliveryContainer />} />
            <Route path='finality' element={<FinalityContainer />} />

            {/* <Route path='messages' element={<MessagesContainer />} /> */}
            {/* <Route path='settings' element={<SettingsContainer />} /> */}
            
            <Route key='*' path='*' element={<Page404 />} />
        </Routes>
    )
}

export default MainRoutes