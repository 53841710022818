import moment from 'moment'

import { ICompanies } from './_types'

import { CardCompanyContainer } from 'components/smart/cardCompany'

import imgEmty from 'images/empty.svg'
import { IconAdd, IconClearClose, IconEntity, IconInfo, IconSearch, IconUpdate } from 'images/icons'

import { Empty, Flex, Grid, ToolTip, Pagination, Loader, Input, Divider, Button, Table } from 'components/ui'


/** Контрагенты */
export const Companies = ({
    isLoading, txtSearch, currentPageNumber, totalCount, pageSize, companiesList, cardCompanies,
    loadDataCompanies, txtSearchOnChange, handleOpenCardCompany,
}: ICompanies) => {
    return <>
        <CardCompanyContainer {...cardCompanies.value} />

        <Flex gap="3px" className="page_container">
            <Loader isOpen={isLoading} backdrop color="#f34e2e" title="Загрузка контрагентов..." />

            <Flex gap="10px" direction="row" justifyContent="space-between" alignItems="center" className="page_container-header">
                <Grid alignItems="center" gap="6px" gridTemplate="columns" gridTemplateColumns="auto 1fr" className="page_container-header-title">
                    <IconEntity width={28} height={28} />
                    <h4>Контрагенты ({totalCount})</h4>
                </Grid>

                <Flex gap="6px" direction="row" className="page_header_for_mobile">
                    <Input size="sm" placeholder="Поиск..." paddingLeft={6} width='100%'
                        value={txtSearch.value} onChange={(e) => txtSearchOnChange(e.target.value)}
                        paddingRight={4} prefix={<IconSearch width={16} height={16} />}
                        suffix={<>
                            {txtSearch.value && <Button id='tooltipBtnClear' size="xs" variant="icon" onClick={() => txtSearchOnChange('')}><IconClearClose width={18} height={18} /></Button>}
                            <Button id='tooltipBtnSearchInfo' size="xs" variant="icon"><IconInfo width={18} height={18} /></Button>
                        </>}
                    />

                    <Button id='tooltipBtnUpdate' size="sm" variant="icon" onClick={() => loadDataCompanies(txtSearch.value)}><IconUpdate /></Button>
                    <Button id='tooltipBtnCreate' size="sm" variant="icon" onClick={() => handleOpenCardCompany(true, "new")}><IconAdd /></Button>
                </Flex>
            </Flex>

            <Divider variant="dark" />

            <Table.Container loadingTitle="Загрузка...">
                <Table.Header id='hiddenForMobile' classGridTemplateColumns="companies_grid_columns" marginLeft="80px" marginRight="10px">
                    <span>Наименование контрагента<br />ИНН / КПП контрагента</span>
                    <span className='hiddenFor750'>Ф.И.О. руководителя<br />Юридический адрес контрагента</span>
                    <span className="hiddenFor950 table_column_data_center">Дата создания</span>
                </Table.Header>

                <Table.Body>
                    {companiesList.length === 0
                        ? <Empty image={imgEmty} />
                        : companiesList.map((item) => (
                            <Table.Row key={item.companyID} indexContent={<span>{item.companyID}</span>} indexContentWidth="68px" paddingInset="2px 0 2px 12px"
                                badge={<p className="table_badge" style={{ background: item.countFiles === 0 ? 'red' : 'green' }}>{item.countFiles}</p>}
                                classGridTemplateColumns="companies_grid_columns" 
                                onClick={() => handleOpenCardCompany(true, "edit", item.companyID)}>

                                <div style={{ textWrap: 'nowrap', overflow: 'hidden' }}>
                                    <p>{item.companyNameShort}</p>
                                    <Flex gap='5px' direction='row'><p style={{opacity: '.7', color: `${item.companyINN.length > 12 && 'red'}`}}>{item.companyINN}</p> / <p style={{ opacity: '.7' }}>{item.companyKPP || '----'}</p></Flex>
                                </div>

                                <div className='hiddenFor750' style={{ textWrap: 'nowrap', overflow: 'hidden' }}>
                                    <p>{item.lastName} {item.firstName} {item.secondName}</p>
                                    <p style={{ opacity: '.7' }}>{item.address || '----'}</p>
                                </div>

                                <span className="hiddenFor950 table_column_data_center">{moment(item.dateCreate).format('DD.MM.YYYY')}<br />{moment(item.dateCreate).format('HH:mm:ss')}</span>
                            </Table.Row>
                        ))
                    }
                </Table.Body>
            </Table.Container>

            <Flex direction="row" justifyContent="flex-end" alignItems="center" className="page_container-footer">
                <Pagination totalCount={totalCount} pageSize={pageSize} currentPage={currentPageNumber.value} onChange={(e) => currentPageNumber.setValue(e)} />
            </Flex>
        </Flex>

        <ToolTip anchorSelect="#tooltipBtnSearchInfo" place="bottom-end">Поиск по:<br/>🗸 Фамилии<br/>🗸 Имени<br/>🗸 Отчеству<br/>🗸 ИНН<br/>🗸 КПП<br/>🗸 Наименованию полному<br/>🗸 Наименованию короткому<br/>🗸 Адресу<br/>🗸 Реквизитам</ToolTip>

        <ToolTip anchorSelect="#tooltipBtnUpdate" content='Обновить список' place="bottom-end" />
        <ToolTip anchorSelect="#tooltipBtnCreate" content='Создать контрагента' place="bottom-end" />
        <ToolTip anchorSelect="#tooltipBtnClear" content='Очистить' place="bottom-end" />
    </>
}