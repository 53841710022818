import { AxiosError, AxiosResponse } from "axios"

import api from "../../services/api.service"
import { IResponseCommon } from "../common.type"
import { 
    IResponseApplicationsStatistics, IResponseApplications, IResponseApplicationsPromo,
    IApplicationsFilter, IResponseApplicationCard, IApplicationFotmSendedData, IResponseApplicationCheckPhone,
} from "./applications.types"


const route = '/applications'


/** Получение статистики по заявкам */
export const getApplicationsStatistics = async () => {
    let response: IResponseApplicationsStatistics

    try {
        const res: AxiosResponse<IResponseApplicationsStatistics, AxiosError> = await api.get(`${route}/getApplicationsStatistics`)

        if (res.statusText !== 'ERR_NETWORK') {
            response = res.data
        } else {
            response = {
                status: 500, 
                message: 'Сервер временно не доступен.',
                errors: null
            }
        }

        return response
    } catch(error) {
        if ((error as AxiosError).code === 'ERR_NETWORK') {response = {status: 500, message: 'Сервер не доступен', errors: (error as AxiosError).code}; return response}

        const resError = (error as AxiosError).response?.data as IResponseApplicationsStatistics
        response = {status: 500, message: resError?.message, errors: resError.errors}
        return response
    }
}
/** Проверка по номеру телефона, существование заявки */
export const getApplicationCheckPhone = async (applicationID: number, clientPhone: string) => {
    let response: IResponseApplicationCheckPhone

    try {
        const res: AxiosResponse<IResponseApplicationCheckPhone, AxiosError> = await api.get(`${route}/getApplicationCheckPhone`, {params: {applicationID, clientPhone}})

        if (res.statusText !== 'ERR_NETWORK') {
            response = res.data
        } else {
            response = {
                status: 500, 
                message: 'Сервер временно не доступен.',
                errors: null
            }
        }

        return response
    } catch(error) {
        if ((error as AxiosError).code === 'ERR_NETWORK') {response = {status: 500, message: 'Сервер не доступен', errors: (error as AxiosError).code}; return response}

        const resError = (error as AxiosError).response?.data as IResponseApplicationCheckPhone
        response = {status: 500, message: resError?.message, errors: resError.errors}
        return response
    }
}



/** Получение заявок */
export const getApplications = async (pageNumber: number, search?: string, filter?: IApplicationsFilter) => {
    let response: IResponseApplications

    try {
        const res: AxiosResponse<IResponseApplications, AxiosError> = await api.get(`${route}/getApplications`, {params: {pageNumber, search, filter}})

        if (res.statusText !== 'ERR_NETWORK') {
            response = res.data
        } else {
            response = {
                status: 500, 
                message: 'Сервер временно не доступен.',
                errors: null
            }
        }

        return response
    } catch(error) {
        if ((error as AxiosError).code === 'ERR_NETWORK') {response = {status: 500, message: 'Сервер не доступен', errors: (error as AxiosError).code}; return response}

        const resError = (error as AxiosError).response?.data as IResponseApplications
        response = {status: 500, message: resError?.message, errors: resError.errors}
        return response
    }
}
/** Получение заявок промо, с МАКПП */
export const getApplicationsPromo = async (pageNumber: number, search?: string) => {
    let response: IResponseApplicationsPromo

    try {
        const res: AxiosResponse<IResponseApplicationsPromo, AxiosError> = await api.get(`${route}/getApplicationsPromo`, {params: {pageNumber, search}})

        if (res.statusText !== 'ERR_NETWORK') {
            response = res.data
        } else {
            response = {
                status: 500,
                message: 'Сервер временно не доступен.',
                errors: null
            }
        }

        return response
    } catch(error) {
        if ((error as AxiosError).code === 'ERR_NETWORK') {response = {status: 500, message: 'Сервер не доступен', errors: (error as AxiosError).code}; return response}

        const resError = (error as AxiosError).response?.data as IResponseApplicationsPromo
        response = {status: 500, message: resError?.message, errors: resError.errors}
        return response
    }
}



/** Получение данных по карточке заявки */
export const getApplicationCard = async (applicationID: number) => {
    let response: IResponseApplicationCard

    try {
        const res: AxiosResponse<IResponseApplicationCard, AxiosError> = await api.get(`${route}/getApplicationCard`, {params: {applicationID}})

        if (res.statusText !== 'ERR_NETWORK') {
            response = res.data
        } else {
            response = {
                status: 500,
                message: 'Сервер временно не доступен.',
                errors: null
            }
        }

        return response
    } catch(error) {
        if ((error as AxiosError).code === 'ERR_NETWORK') {response = {status: 500, message: 'Сервер не доступен', errors: (error as AxiosError).code}; return response}

        const resError = (error as AxiosError).response?.data as IResponseApplicationCard
        response = {status: 500, message: resError?.message, errors: resError.errors}
        return response
    }
}
/** Сохранение данных по карточке заявки */
export const setApplicationCardSave = async (applicationID: number, data: IApplicationFotmSendedData) => {
    let response: IResponseCommon

    try {
        const res: AxiosResponse<IResponseCommon, AxiosError> = await api.put(`${route}/setApplicationCardSave?applicationID=${applicationID}`, {applicationData: data})

        if (res.statusText !== 'ERR_NETWORK') {
            response = res.data
        } else {
            response = {
                status: 500,
                message: 'Сервер временно не доступен.',
                errors: null
            }
        }

        return response
    } catch(error) {
        if ((error as AxiosError).code === 'ERR_NETWORK') {response = {status: 500, message: 'Сервер не доступен', errors: (error as AxiosError).code}; return response}

        const resError = (error as AxiosError).response?.data as IResponseCommon
        response = {status: 500, message: resError?.message, errors: resError.errors}
        return response
    }
}


/** Скачивание файла учебного плана */
export const downloadFile = async (dataBase: string, filePath: string) => {
    let response: any

    try {
        const res: AxiosResponse<any, AxiosError> = await api.get(`${route}/downloadFile`, { params: { dataBase, filePath }, responseType: 'blob' })

        if (res.statusText !== 'ERR_NETWORK') {
            response = res.data
        } else {
            response = {
                status: 500,
                message: 'Сервер временно не доступен.',
                errors: null
            }
        }

        return response
    } catch(error) {
        if ((error as AxiosError).code === 'ERR_NETWORK') {response = {status: 500, message: 'Сервер не доступен', errors: (error as AxiosError).code}; return response}

        const resError = (error as AxiosError).response?.data as any
        response = {status: 500, message: resError?.message, errors: resError.errors}
        return response
    }
}



/** Отправка в Telegram канал - Информация для клиента */
export const sendTelegramClientInfo = async (applicationID: number) => {
    let response: IResponseCommon

    try {
        const res: AxiosResponse<IResponseCommon, AxiosError> = await api.post(`${route}/sendTelegramClientInfo?applicationID=${applicationID}`)

        if (res.statusText !== 'ERR_NETWORK') {
            response = res.data
        } else {
            response = {
                status: 500,
                message: 'Сервер временно не доступен.',
                errors: null
            }
        }

        return response
    } catch(error) {
        if ((error as AxiosError).code === 'ERR_NETWORK') {response = {status: 500, message: 'Сервер не доступен', errors: (error as AxiosError).code}; return response}

        const resError = (error as AxiosError).response?.data as IResponseCommon
        response = {status: 500, message: resError?.message, errors: resError.errors}
        return response
    }
}
/** Отправка в Telegram канал - Учебный план */
export const sendTelegramEducationPlan = async (applicationID: number) => {
    let response: IResponseCommon

    try {
        const res: AxiosResponse<IResponseCommon, AxiosError> = await api.post(`${route}/sendTelegramEducationPlan?applicationID=${applicationID}`)

        if (res.statusText !== 'ERR_NETWORK') {
            response = res.data
        } else {
            response = {
                status: 500,
                message: 'Сервер временно не доступен.',
                errors: null
            }
        }

        return response
    } catch(error) {
        if ((error as AxiosError).code === 'ERR_NETWORK') {response = {status: 500, message: 'Сервер не доступен', errors: (error as AxiosError).code}; return response}

        const resError = (error as AxiosError).response?.data as IResponseCommon
        response = {status: 500, message: resError?.message, errors: resError.errors}
        return response
    }
}
/** Отправка (ДЕМО + ИНФО) в Telegram канал - Информация для клиента */
export const sendTelegramClientDemoInfo = async (applicationID: number) => {
    let response: IResponseCommon

    try {
        const res: AxiosResponse<IResponseCommon, AxiosError> = await api.post(`${route}/sendTelegramClientDemoInfo?applicationID=${applicationID}`)

        if (res.statusText !== 'ERR_NETWORK') {
            response = res.data
        } else {
            response = {
                status: 500,
                message: 'Сервер временно не доступен.',
                errors: null
            }
        }

        return response
    } catch(error) {
        if ((error as AxiosError).code === 'ERR_NETWORK') {response = {status: 500, message: 'Сервер не доступен', errors: (error as AxiosError).code}; return response}

        const resError = (error as AxiosError).response?.data as IResponseCommon
        response = {status: 500, message: resError?.message, errors: resError.errors}
        return response
    }
}
/** Отправка (ПОВТОРНЫЙ КЛИЕНТ) в Telegram канал - Информация для клиента */
export const sendTelegramClientRepeat = async (applicationID: number) => {
    let response: IResponseCommon

    try {
        const res: AxiosResponse<IResponseCommon, AxiosError> = await api.post(`${route}/sendTelegramClientRepeat?applicationID=${applicationID}`)

        if (res.statusText !== 'ERR_NETWORK') {
            response = res.data
        } else {
            response = {
                status: 500,
                message: 'Сервер временно не доступен.',
                errors: null
            }
        }

        return response
    } catch(error) {
        if ((error as AxiosError).code === 'ERR_NETWORK') {response = {status: 500, message: 'Сервер не доступен', errors: (error as AxiosError).code}; return response}

        const resError = (error as AxiosError).response?.data as IResponseCommon
        response = {status: 500, message: resError?.message, errors: resError.errors}
        return response
    }
}

/** Отправка в Telegram канал - Решение клиента обучаться */
export const sendTelegramClientDecisionLearn = async (applicationID: number) => {
    let response: IResponseCommon

    try {
        const res: AxiosResponse<IResponseCommon, AxiosError> = await api.post(`${route}/sendTelegramClientDecisionLearn?applicationID=${applicationID}`)

        if (res.statusText !== 'ERR_NETWORK') {
            response = res.data
        } else {
            response = {
                status: 500,
                message: 'Сервер временно не доступен.',
                errors: null
            }
        }

        return response
    } catch(error) {
        if ((error as AxiosError).code === 'ERR_NETWORK') {response = {status: 500, message: 'Сервер не доступен', errors: (error as AxiosError).code}; return response}

        const resError = (error as AxiosError).response?.data as IResponseCommon
        response = {status: 500, message: resError?.message, errors: resError.errors}
        return response
    }
}

/** Отправка в Telegram канал - Обучение уже началось */
export const sendTelegramClientTrainingBegun = async (applicationID: number) => {
    let response: IResponseCommon

    try {
        const res: AxiosResponse<IResponseCommon, AxiosError> = await api.post(`${route}/sendTelegramClientTrainingBegun?applicationID=${applicationID}`)

        if (res.statusText !== 'ERR_NETWORK') {
            response = res.data
        } else {
            response = {
                status: 500,
                message: 'Сервер временно не доступен.',
                errors: null
            }
        }

        return response
    } catch(error) {
        if ((error as AxiosError).code === 'ERR_NETWORK') {response = {status: 500, message: 'Сервер не доступен', errors: (error as AxiosError).code}; return response}

        const resError = (error as AxiosError).response?.data as IResponseCommon
        response = {status: 500, message: resError?.message, errors: resError.errors}
        return response
    }
}

/** Отправка в Telegram канал - Промо */
export const sendTelegramClientNewYear = async (applicationID: number) => {
    let response: IResponseCommon

    try {
        const res: AxiosResponse<IResponseCommon, AxiosError> = await api.post(`${route}/sendTelegramClientNewYear?applicationID=${applicationID}`)

        if (res.statusText !== 'ERR_NETWORK') {
            response = res.data
        } else {
            response = {
                status: 500,
                message: 'Сервер временно не доступен.',
                errors: null
            }
        }

        return response
    } catch(error) {
        if ((error as AxiosError).code === 'ERR_NETWORK') {response = {status: 500, message: 'Сервер не доступен', errors: (error as AxiosError).code}; return response}

        const resError = (error as AxiosError).response?.data as IResponseCommon
        response = {status: 500, message: resError?.message, errors: resError.errors}
        return response
    }
}

/** Отправка EMAIL - На почту клиенту */
export const sendEmailClientInfo = async (applicationID: number) => {
    let response: IResponseCommon

    try {
        const res: AxiosResponse<IResponseCommon, AxiosError> = await api.post(`${route}/sendEmailClientInfo?applicationID=${applicationID}`)

        if (res.statusText !== 'ERR_NETWORK') {
            response = res.data
        } else {
            response = {
                status: 500,
                message: 'Сервер временно не доступен.',
                errors: null
            }
        }

        return response
    } catch(error) {
        if ((error as AxiosError).code === 'ERR_NETWORK') {response = {status: 500, message: 'Сервер не доступен', errors: (error as AxiosError).code}; return response}

        const resError = (error as AxiosError).response?.data as IResponseCommon
        response = {status: 500, message: resError?.message, errors: resError.errors}
        return response
    }
}

/** Создание демо-доступа и отправка в Telegram канал - Демо доступ */
export const createDemoAccess = async (applicationID: number) => {
    let response: IResponseCommon

    try {
        const res: AxiosResponse<IResponseCommon, AxiosError> = await api.post(`${route}/createDemoAccess?applicationID=${applicationID}`)

        if (res.statusText !== 'ERR_NETWORK') {
            response = res.data
        } else {
            response = {
                status: 500,
                message: 'Сервер временно не доступен.',
                errors: null
            }
        }

        return response
    } catch(error) {
        if ((error as AxiosError).code === 'ERR_NETWORK') {response = {status: 500, message: 'Сервер не доступен', errors: (error as AxiosError).code}; return response}

        const resError = (error as AxiosError).response?.data as IResponseCommon
        response = {status: 500, message: resError?.message, errors: resError.errors}
        return response
    }
}



/** Отправка в Telegram канал - Промо (МАКПП) */
export const sendTelegramAppPromo = async (applicationID: number) => {
    let response: IResponseCommon

    try {
        const res: AxiosResponse<IResponseCommon, AxiosError> = await api.post(`${route}/sendTelegramAppPromo?applicationID=${applicationID}`)

        if (res.statusText !== 'ERR_NETWORK') {
            response = res.data
        } else {
            response = {
                status: 500,
                message: 'Сервер временно не доступен.',
                errors: null
            }
        }

        return response
    } catch(error) {
        if ((error as AxiosError).code === 'ERR_NETWORK') {response = {status: 500, message: 'Сервер не доступен', errors: (error as AxiosError).code}; return response}

        const resError = (error as AxiosError).response?.data as IResponseCommon
        response = {status: 500, message: resError?.message, errors: resError.errors}
        return response
    }
}