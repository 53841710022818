import { useEffect, useRef } from "react"

import { IFinalExamination } from "./_types"

import imgEmty from 'images/empty.svg'
import { IconArchive, IconClearClose, IconContracts, IconDiplom, IconInfo, IconJobs, IconListeners, IconSearch, IconTelegram, IconInArchive, IconUpdate } from "images/icons"

import { Empty, Flex, Grid, ToolTip, Pagination, Loader, Input, Divider, Button, Badge, Table } from "components/ui"


/** Итоговые работы */
export const FinalExamination = ({
    isLoading, txtSearch, archiveCount, countUnverifiedWorks, countNewPrintDiplom, loadingTitle, currentPageNumber, totalCount, pageSize, listenersList, 
    loadDataListeners, txtSearchOnChange, handlerOpenModalConfirm, handleOpenCardContract, handleOpenCardListener, 
    handleOpenCardFinalExamination, handleOpenFinalExaminationArchive, handleOpenFinalExaminationJobs, handleOpenFinalExaminationPrintings,
}: IFinalExamination) => {
    const tableNavRef = useRef<HTMLDivElement>(null)
    const tableHeadNavRef = useRef<HTMLSpanElement>(null)

    useEffect(() => {
        if (tableNavRef.current && tableHeadNavRef.current) {
            const width = tableNavRef.current.offsetWidth;
            tableHeadNavRef.current.style.width = `${width}px`;
        }
    }, [listenersList, tableNavRef.current, tableHeadNavRef.current]) // eslint-disable-line react-hooks/exhaustive-deps

    return <>
        <Flex gap="3px" className="page_container">
            <Loader isOpen={isLoading} backdrop color="#f34e2e" title={loadingTitle.value} />

            <Flex gap="10px" direction="row" justifyContent="space-between" alignItems="center" className="page_container-header">
                <Grid alignItems="center" gap="6px" gridTemplate="columns" gridTemplateColumns="auto 1fr" className="page_container-header-title">
                    <IconJobs width={28} height={28} />
                    <h4>Итоговые работы ({totalCount})</h4>
                </Grid>

                <Flex gap="6px" direction="row" className="page_header_for_mobile">
                    <Input size="sm" placeholder="Поиск..." paddingLeft={6} width='100%'
                        value={txtSearch.value} onChange={(e) => txtSearchOnChange(e.target.value)}
                        paddingRight={4} prefix={<IconSearch width={16} height={16} />}
                        suffix={<>
                            {txtSearch.value && <Button id='tooltipBtnClear' size="xs" variant="icon" onClick={() => txtSearchOnChange('')}><IconClearClose width={18} height={18} /></Button>}
                            <Button id='tooltipBtnSearchInfo' size="xs" variant="icon"><IconInfo width={18} height={18} /></Button>
                        </>}
                    />

                    <Button id='tooltipBtnUpdate' size="sm" variant="icon" onClick={() => loadDataListeners(txtSearch.value)}><IconUpdate /></Button>

                    <Divider type="vertical" margin="3px" />

                    <Button id='tooltipBtnArchiveList' size="sm" variant="icon" onClick={() => handleOpenFinalExaminationArchive(true)}>
                        <IconArchive />
                        <Badge isVisible={archiveCount > 0}>{archiveCount}</Badge>
                    </Button>

                    <Button id='tooltipBtnPrintDiplomList' size="sm" variant="icon" onClick={() => handleOpenFinalExaminationPrintings(true)}>
                        <IconDiplom />
                        <Badge isVisible={countNewPrintDiplom > 0}>{countNewPrintDiplom}</Badge>
                    </Button>
                    
                    <Divider type="vertical" margin="3px" />

                    <Button id='tooltipBtnJobs' size="sm" variant="icon" onClick={() => handleOpenFinalExaminationJobs(true)}>
                        <IconTelegram />
                        <Badge isVisible={countUnverifiedWorks > 0}>{countUnverifiedWorks}</Badge>
                    </Button>
                </Flex>
            </Flex>

            <Divider variant="dark" />

            <Table.Container loadingTitle="Загрузка...">
                <Table.Header id='hiddenForMobile' classGridTemplateColumns="finalExamination_grid_columns" marginLeft="76px" marginRight="10px">
                    <span>Тип договора / ФИО слушателя<br />Документ / Аттестация / Долг / ИАР / Тест</span>
                    <span className=''>Программа<br />Тема итоговой работы</span>
                    <span className="hiddenFor950 table_column_data_center">№ и дата договора</span>
                    <span className="hiddenFor950 table_column_data_center">Начало обуч.<br />Конец обуч.</span>
                    <span ref={tableHeadNavRef} className="hiddenFor750"></span>
                </Table.Header>

                <Table.Body>
                    {listenersList.length === 0
                        ? <Empty image={imgEmty} />
                        : listenersList.map((item) => (
                            <Table.Row key={item.finalExaminationID} indexContent={<span>{item.applicationID}</span>} indexContentWidth="68px" paddingInset="0px 0 0px 8px"
                                // badge={<p className="table_badge" style={{ background: item.countFiles === 0 ? 'red' : 'green' }}>{item.countFiles}</p>}
                                backgroundColor={`${item.defaulColorStatus}`} backgroundColorInset={`${item.contractStatusID === 6 && 'linear-gradient(0deg, #f9a696 0%, #fef6f4 100%)'}`} classGridTemplateColumns="finalExamination_grid_columns" 
                                onClick={() => handleOpenCardFinalExamination(true, item.finalExaminationID, `${item.lastName} ${item.firstName} ${item.secondName || ''}`)}>

                                <div style={{ textWrap: 'nowrap', overflow: 'hidden' }}>
                                    <p>{item.contractTypeName} 👉 {item.lastName} {item.firstName} {item.secondName}</p>
                                    <Flex gap='5px' direction='row'>
                                        <div style={{ opacity: '.7' }}>
                                            <span>{item.documentTypeName}</span> / <span>{item.attestationTypeName}</span> / <b style={{ color: `${item.dutyPayment > 0 && 'red'}` }}>{item.dutyPayment}</b> / <span style={{ color: `${(item.attestationTypeName === 'ВР' && !item.scoreIAR) && 'red'}` }}>{item.scoreIAR || 0}</span> / <span style={{ color: `${(item.attestationTypeName === 'ИТ' && !item.scoreTest) && 'red'}` }}>{item.scoreTest || 0}</span>
                                        </div>
                                    </Flex>
                                </div>

                                <div className='hiddenForMobile' style={{ textWrap: 'nowrap', overflow: 'hidden' }} data-tooltip-id="tooltipThemeIAR" data-tooltip-content={item.themeIAR}>
                                    <p>{item.courseName}</p>
                                    <p style={{ opacity: '.7' }}>{item.themeIAR || '----'}</p>
                                </div>

                                <div className='hiddenFor950 table_column_data_center' style={{ textWrap: 'nowrap', overflow: 'hidden' }}>
                                    <p>{item.contractNumber}</p>
                                    <p style={{ opacity: '.7' }}>{item.contractDate}</p>
                                </div>

                                <div className="hiddenFor950 table_column_data_center">
                                    <p>{item.dateEducationStart}</p>
                                    <p style={{ color: `${item.warningEndEducationDate === 1 && 'red'}`, fontWeight: `${item.warningEndEducationDate === 1 && '700'}` }}>{item.dateEducationEnd}</p>
                                </div>

                                <nav id='hiddenFor750' ref={tableNavRef}>
                                    <Button id='tooltipBtnToPrint' variant="icon" size="xs" isDisabled={item.toPrint === 0} onClick={(e) => {handlerOpenModalConfirm(true, 'На печать', `Вы действительно хотите отправить в печать следующего слушателя:<br />👉 ${item.lastName} ${item.firstName} ${item.secondName};<br />👉 Договор № ${item.contractNumber}.`, 'toPrint', item.finalExaminationID);e.stopPropagation()}}><IconDiplom /></Button>
                                    <Button id='tooltipBtnCardContract' variant="icon" size="xs" onClick={(e) => {handleOpenCardContract(true, item.contractID); e.stopPropagation()}}><IconContracts /></Button>
                                    <Button id='tooltipBtnCardListener' variant="icon" size="xs" onClick={(e) => {handleOpenCardListener(true, item.listenerID); e.stopPropagation()}}><IconListeners /></Button>
                                    <Divider type="vertical" size="20px" />
                                    <Button id='tooltipBtnInArchive' variant="icon" size="xs" onClick={(e) => {handlerOpenModalConfirm(true, 'Отправка в архив', `Вы действительно хотите отправить в архив следующего слушателя:<br />👉 ${item.lastName} ${item.firstName} ${item.secondName};<br />👉 Договор № ${item.contractNumber}.`, 'inArchive', item.finalExaminationID); e.stopPropagation()}}><IconInArchive /></Button>
                                </nav>
                            </Table.Row>
                        ))
                    }
                </Table.Body>
            </Table.Container>

            <Flex direction="row" justifyContent="flex-end" alignItems="center" className="page_container-footer">
                <Pagination totalCount={totalCount} pageSize={pageSize} currentPage={currentPageNumber.value} onChange={(e) => currentPageNumber.setValue(e)} />
            </Flex>
        </Flex>

        <ToolTip anchorSelect="#tooltipBtnSearchInfo" place="bottom-end">Поиск по:<br/>🗸 Фамилии<br/>🗸 Имени<br/>🗸 Отчеству<br/>🗸 Телефону<br/>🗸 Email<br/>🗸 СНИЛС<br/>🗸 Номеру договора<br/>🗸 Идентификатору заявки<br/>🗸 Наименованию курса</ToolTip>

        <ToolTip anchorSelect="#tooltipBtnUpdate" content='Обновить список' place="bottom-end" />
        <ToolTip anchorSelect="#tooltipBtnJobs" content='Работы для проверки' place="bottom-end" />
        <ToolTip anchorSelect="#tooltipBtnClear" content='Очистить' place="bottom-end" />
        <ToolTip anchorSelect="#tooltipBtnArchiveList" content='Архив слушателей' place="bottom-end" />
        <ToolTip anchorSelect="#tooltipBtnPrintDiplomList" content='В печать' place="bottom-end" />
        
        <ToolTip anchorSelect="#tooltipBtnToPrint" content='Отправить в печать' place="top-end" />
        <ToolTip anchorSelect="#tooltipBtnCardContract" content='Анкета договора' place="bottom-end" />
        <ToolTip anchorSelect="#tooltipBtnCardListener" content='Анкета слушателя' place="top-end" />
        <ToolTip anchorSelect="#tooltipBtnInArchive" content='Переместить в архив' place="bottom-end" />

        <ToolTip id="tooltipThemeIAR" place="top" />
    </>
}