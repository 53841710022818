import {create} from 'zustand'
import {devtools} from 'zustand/middleware'

import { 
    createDemoAccess, getApplicationCard, getApplicationCheckPhone, getApplications, getApplicationsPromo, getApplicationsStatistics, 
    sendEmailClientInfo, sendTelegramAppPromo, sendTelegramClientDecisionLearn, sendTelegramClientDemoInfo, sendTelegramClientInfo, 
    sendTelegramEducationPlan, sendTelegramClientNewYear, sendTelegramClientRepeat, 
    sendTelegramClientTrainingBegun, setApplicationCardSave, downloadFile
} from './applications.api'
import {
    IApplicationsState, IApplicationsActions, IApplicationsList, IApplicationsPromoList, IApplicationsFilter, IApplicationsStatistics,
    IApplicationCard,
    IApplicationFotmSendedData
} from './applications.types'


export const initialStateApplications: IApplicationsState = {
    error: null,
    applicationsStatistics: {} as IApplicationsStatistics,
    isReloadApplications: false,
    isLoadingCard: false,
 
    applications: {
        isLoading: false,

        items: [] as IApplicationsList[],
        totalCount: 0,
        pageSize: 0,
        currentPage: 0
    },

    applicationsPromo: {
        isLoading: false,

        items: [] as IApplicationsPromoList[],
        totalCount: 0,
        pageSize: 0,
        currentPage: 0
    }
}

/** Хранилище страницы - Заявки */
export const useApplications = create<IApplicationsState & IApplicationsActions>()(devtools((set) => ({
    ...initialStateApplications,


    clearApplicationsState: () => {set(initialStateApplications)},
   
    /** Очистка хранилиа заявок промо */
    clearApplicationsPromo: () => {
        set((state) => ({
            applicationsPromo: {
                ...state.applicationsPromo,
                items: [],
                totalCount: 0,
                pageSize: 0,
                currentPage: 0,
                archiveCount: 0
            }
        }))
    },
    /** Получение списка заявок */
    actionGetApplications: async (pageNumber: number, search: string, filter?: IApplicationsFilter) => {
        set((state) => ({ 
            applications: { 
                ...state.applications, 
                isLoading: !state.isReloadApplications 
            } 
        }))

        const resStat = await getApplicationsStatistics()
        if (resStat.status !== 200) set({ error: {type: resStat.status === 500 ? 'error' : 'warning', message: resStat.message} })

        const res = await getApplications(pageNumber, search, filter)
        if (res.status !== 200) set({ error: {type: res.status === 500 ? 'error' : 'warning', message: res.message} })

        if (res.message === 'OK') {
            set({error: null})
            set({isReloadApplications: false})

            set((state) => ({
                applicationsStatistics: {
                    ...state.applicationsStatistics,
                    ...resStat.data
                },
                applications: {
                    ...state.applications, 
                    items: res.data!.data,
                    totalCount: res.data!.totalCount,
                    pageSize: res.data!.pageSize,
                    currentPage: res.data!.currentPage
                }
            }))
        }

        set((state) => ({ 
            applications: { ...state.applications, isLoading: false } 
        }))

        return res.status
    },
    /** Проверка по номеру телефона, существование заявки */
    actionGetApplicationCheckPhone: async (applicationID: number, clientPhone: string) => {
        const res = await getApplicationCheckPhone(applicationID, clientPhone)
        if (res.status !== 200) set({ error: {type: res.status === 500 ? 'error' : 'warning', message: res.message} })

        if (res.message === 'OK') {
            set({ error: null })
            return res.data
        }
    },

    /** Получение списка заявок промо, с МАКПП */
    actionGetApplicationsPromo: async (pageNumber: number, search: string) => {
        set((state) => ({applicationsPromo: {...state.applicationsPromo, isLoading: true}}))

        const res = await getApplicationsPromo(pageNumber, search)
        if (res.status !== 200) set({ error: {type: res.status === 500 ? 'error' : 'warning', message: res.message} })

        if (res.message === 'OK') {
            set({error: null})

            set((state) => ({
                applicationsPromo: {
                    ...state.applicationsPromo, 
                    items: res.data!.data,
                    totalCount: res.data!.totalCount,
                    pageSize: res.data!.pageSize,
                    currentPage: res.data!.currentPage
                }
            }))
        }

        set((state) => ({applicationsPromo: {...state.applicationsPromo, isLoading: false}}))
    },

    /** Получение данных по карточке заявки */
    actionGetApplicationCard: async (applicationID: number) => {
        let returnData: IApplicationCard | null = null
        set({isLoadingCard: true})

        const res = await getApplicationCard(applicationID)
        if (res.status !== 200) set({ error: {type: res.status === 500 ? 'error' : 'warning', message: res.message} })

        if (res.message === 'OK') {
            set({error: null})

            if (res.data) returnData = res.data
        }

        set({isLoadingCard: false})

        return returnData
    },
    /** Сохранение данных по анкете заявки */
    actionSetApplicationCard: async (applicationID: number, data: IApplicationFotmSendedData) => {
        set({isLoadingCard: true})

        const res = await setApplicationCardSave(applicationID, data)
        if (res.status !== 200) set({ error: {type: res.status === 500 ? 'error' : 'warning', message: res.message} })

        if (res.message === 'OK') {
            set({ error: null })
            set({isReloadApplications: true})
        }
            
        set({isLoadingCard: false})
        return res.status
    },


    /** Загрузка файла учебного плана */
    actionDownloadFile: async (dataBase: string, filePath: string, fileName: string) => {
        // set((state) => ({applications: {...state.applications, isLoading: true}}))
        // set({isLoadingCard: true})

        const res = await downloadFile(dataBase, filePath)

        if (res.status !== 200 && !!res.status) {
            set({ error: {type: res.status === 500 ? 'error' : 'warning', message: 'Ошибка получения файла!'} })
        } else {
            const url = window.URL.createObjectURL(new Blob([res]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', `${fileName}`)
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
            window.URL.revokeObjectURL(url)
        }

            // set((state) => ({ applications: { ...state.applications, isLoading: false } }))
            // set({ isLoadingCard: false })
    },



    /** Отправка в Telegram канал - Информация для клиента */
    actionSendTelegramClientInfo: async (applicationID: number) => {
        set((state) => ({applications: {...state.applications, isLoading: true}}))
        set({isLoadingCard: true})

        const res = await sendTelegramClientInfo(applicationID)
        if (res.status !== 200) set({ error: {type: res.status === 500 ? 'error' : 'warning', message: res.message} })

        if (res.message === 'OK') set({ error: null })

        set((state) => ({applications: {...state.applications, isLoading: false}}))
        set({isLoadingCard: false})
    },
    /** Отправка в Telegram канал - Учебный план */
    actionSendTelegramEducationPlan: async (applicationID: number) => {
        set((state) => ({applications: {...state.applications, isLoading: true}}))
        set({isLoadingCard: true})

        const res = await sendTelegramEducationPlan(applicationID)
        if (res.status !== 200) set({ error: {type: res.status === 500 ? 'error' : 'warning', message: res.message} })

        if (res.message === 'OK') set({ error: null })

        set((state) => ({applications: {...state.applications, isLoading: false}}))
        set({isLoadingCard: false})
    },
    /** Отправка (ДЕМО + ИНФО) в Telegram канал - Информация для клиента */
    actionSendTelegramClientDemoInfo: async (applicationID: number) => {
        set((state) => ({applications: {...state.applications, isLoading: true}}))
        set({isLoadingCard: true})

        const res = await sendTelegramClientDemoInfo(applicationID)
        if (res.status !== 200) set({ error: {type: res.status === 500 ? 'error' : 'warning', message: res.message} })

        if (res.message === 'OK') set({ error: null })

        set((state) => ({applications: {...state.applications, isLoading: false}}))
        set({isLoadingCard: false})
    },
    /** Отправка (ПОВТОРНЫЙ КЛИЕНТ) в Telegram канал - Информация для клиента */
    actionSendTelegramClientRepeat: async (applicationID: number) => {
        set((state) => ({applications: {...state.applications, isLoading: true}}))
        set({isLoadingCard: true})

        const res = await sendTelegramClientRepeat(applicationID)
        if (res.status !== 200) set({ error: {type: res.status === 500 ? 'error' : 'warning', message: res.message} })

        if (res.message === 'OK') set({ error: null })

        set((state) => ({applications: {...state.applications, isLoading: false}}))
        set({isLoadingCard: false})
    },
    /** Отправка в Telegram канал - Решение клиента обучаться */
    actionSendTelegramClientDecisionLearn: async (applicationID: number) => {
        set((state) => ({applications: {...state.applications, isLoading: true}}))
        set({isLoadingCard: true})

        const res = await sendTelegramClientDecisionLearn(applicationID)
        if (res.status !== 200) set({ error: {type: res.status === 500 ? 'error' : 'warning', message: res.message} })

        if (res.message === 'OK') set({ error: null })

        set((state) => ({applications: {...state.applications, isLoading: false}}))
        set({isLoadingCard: false})
    },
    /** Отправка в Telegram канал - Обучение уже началось */
    actionSendTelegramClientTrainingBegun: async (applicationID: number) => {
        set((state) => ({applications: {...state.applications, isLoading: true}}))
        set({isLoadingCard: true})

        const res = await sendTelegramClientTrainingBegun(applicationID)
        if (res.status !== 200) set({ error: {type: res.status === 500 ? 'error' : 'warning', message: res.message} })

        if (res.message === 'OK') set({ error: null })

        set((state) => ({applications: {...state.applications, isLoading: false}}))
        set({isLoadingCard: false})
    },
    /** Отправка в Telegram канал - Промо */
    actionSendTelegramClientNewYear: async (applicationID: number) => {
        set((state) => ({applications: {...state.applications, isLoading: true}}))
        set({isLoadingCard: true})

        const res = await sendTelegramClientNewYear(applicationID)
        if (res.status !== 200) set({ error: {type: res.status === 500 ? 'error' : 'warning', message: res.message} })

        if (res.message === 'OK') set({ error: null })

        set((state) => ({applications: {...state.applications, isLoading: false}}))
        set({isLoadingCard: false})
    },
    /** Отправка EMAIL - На почту клиенту */
    actionSendEmailClientInfo: async (applicationID: number) => {
        set((state) => ({applications: {...state.applications, isLoading: true}}))
        set({isLoadingCard: true})

        const res = await sendEmailClientInfo(applicationID)
        if (res.status !== 200) set({ error: {type: res.status === 500 ? 'error' : 'warning', message: res.message} })

        if (res.message === 'OK') set({ error: null })

        set((state) => ({applications: {...state.applications, isLoading: false}}))
        set({isLoadingCard: false})
    },
    /** Создание демо-доступа и отправка в Telegram канал - Демо доступ */
    actionCreateDemoAccess: async (applicationID: number) => {
        set((state) => ({applications: {...state.applications, isLoading: true}}))
        set({isLoadingCard: true})

        const res = await createDemoAccess(applicationID)
        if (res.status !== 200) set({ error: {type: res.status === 500 ? 'error' : 'warning', message: res.message} })

        if (res.message === 'OK') set({ error: null })

        set((state) => ({applications: {...state.applications, isLoading: false}}))
        set({isLoadingCard: false})
    },
    /** Отправка в Telegram канал - Промо (МАКПП) */
    actionSendTelegramAppPromo: async (applicationID: number) => {
        set((state) => ({applications: {...state.applications, isLoading: true}}))
        set({isLoadingCard: true})

        const res = await sendTelegramAppPromo(applicationID)
        if (res.status !== 200) set({ error: {type: res.status === 500 ? 'error' : 'warning', message: res.message} })

        if (res.message === 'OK') set({ error: null })

        set((state) => ({applications: {...state.applications, isLoading: false}}))
        set({isLoadingCard: false})
    },

}), {name: 'applicationsState'}))