import { useContext, useEffect } from "react"
import { useShallow } from "zustand/react/shallow"

import { IFinalExamination } from "./_types"
import { FinalExamination } from "./_interface"

import { useInput } from "hooks/useInput"
import { useDebounce } from "hooks/useDebounce"
import { useFinalExamination } from "store/finalExamination/finalExamination.state"

import { IModalConfirm, ModalConfirm } from "components/dumb/modal.confirm"

import { CardContractContainer, ICardContractContainer } from "components/smart/cardContract"
import { CardListenerContainer, ICardListenerContainer } from "components/smart/cardListener"
import { CardFinalExaminationContainer, ICardFinalExaminationContainer } from "components/smart/cardFinalExamination"
import { FinalExaminationJobsContainer, IFinalExaminationJobsContainer } from "components/smart/finalExaminationJobs"
import { FinalExaminationArchiveContainer, IFinalExaminationArchiveContainer } from "components/smart/finalExaminationArchive"
import { FinalExaminationPrintingsContainer, IFinalExaminationPrintingsContainer } from "components/smart/finalExaminationPrintings"

import { NotificationContext, notifyOpen } from "components/ui/notification/notification.provider"


/** Итоговые работы - Контейнер */
export const FinalExaminationContainer = () => {
    const notify = useContext(NotificationContext)

    const finalExaminationState = useFinalExamination(useShallow((state) => state))
    const cardFinalExamination = useInput<ICardFinalExaminationContainer>({isOpen: false})
    const finalExaminationArchive = useInput<IFinalExaminationArchiveContainer>({isOpen: false})
    const finalExaminationJobs = useInput<IFinalExaminationJobsContainer>({isOpen: false})
    const finalExaminationPrintings = useInput<IFinalExaminationPrintingsContainer>({isOpen: false})
    const cardContract = useInput<ICardContractContainer>({isOpen: false})
    const cardListener = useInput<ICardListenerContainer>({isOpen: false})

    const loadingTitle = useInput<string>('Загрузка итоговых работ...')
    const modalConfirm = useInput<IModalConfirm>({isOpen: false})

    const currentPageNumber = useInput<number>(1)
    const txtSearch = useInput<string>('')

    useEffect(() => {
        return (() => {
            finalExaminationState.clearFinalExaminationState()

            currentPageNumber.setValue(1)
            txtSearch.setValue('')        
        })
    }, []) // eslint-disable-line react-hooks/exhaustive-deps
    
    useEffect(() => {
        if (finalExaminationState.error) return notify && notifyOpen(finalExaminationState.error?.message, finalExaminationState.error?.type, 3000, notify)
    }, [finalExaminationState.error]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        loadDataListeners(txtSearch.value)
    }, [currentPageNumber.value]) // eslint-disable-line react-hooks/exhaustive-deps
    
    useEffect(() => {
        finalExaminationState.isReloadListeners && loadDataListeners(txtSearch.value)
    }, [finalExaminationState.isReloadListeners]) // eslint-disable-line react-hooks/exhaustive-deps

    /** Функция по получению списка слушателей */
    const loadDataListeners = (search?: string) => {
        finalExaminationState.actionGetFinalExaminationListeners(currentPageNumber.value, search || '')
    }



        /** Открытие/закрытие окна подтверждения */
    const handlerOpenModalConfirm = (isOpen: boolean, headerText: string, contentText: string, actionName?: string, recordID?: number) => {
        modalConfirm.setValue({
            isOpen, headerText, contentText, 
            handlerButtonCancel() {modalConfirm.setValue({isOpen: false})}, 
            handlerButtonOk() {
                switch (actionName) {
                    case 'inArchive': {
                        loadingTitle.setValue('Отправляю в архив...')

                        finalExaminationState.actionSetFinalExaminationInArchive(Number(recordID)).then((res) => {
                            if (res === 200) {
                                loadDataListeners(txtSearch.value)
                                return notify && notifyOpen('Анкета итоговой работы слушателя, успешно отправлена в архив.', 'success', 1500, notify)
                            }
                        }).finally(() => { loadingTitle.setValue('Загрузка итоговых работ...') })
                        break
                    } // Создание и получение нового номера по договору

                    case 'toPrint': {
                        loadingTitle.setValue('Отправляю в печать...')

                        finalExaminationState.actionSetFinalExaminationToPrint(Number(recordID)).then((res) => {
                            if (res === 200) {
                                loadDataListeners(txtSearch.value)
                                return notify && notifyOpen('Анкета итоговой работы слушателя, успешно отправлена в печать.', 'success', 1500, notify)
                            }
                        }).finally(() => { loadingTitle.setValue('Загрузка итоговых работ...') })
                        break
                    } // Допуск слушателя к обучению
                }

                modalConfirm.setValue({isOpen: false})
            }
        })
    }
    /** Открытие/закрытие анкеты по договору */
    const handleOpenCardContract = (isOpen: boolean, contractID?: number) => {
        cardContract.setValue({
            isOpen, cardMode: 'edit', cardID: contractID,
            handlerButtonCancel() {cardContract.setValue({isOpen: false})}, 
            handlerButtonOk() {
                cardContract.setValue({isOpen: false})
                loadDataListeners(txtSearch.value)
            }
        })
    }

    /** Открытие/закрытие анкеты по слушателю */
    const handleOpenCardListener = (isOpen: boolean, ListenerID?: number) => {
        cardListener.setValue({
            isOpen, cardMode: 'edit', cardID: ListenerID,
            handlerButtonCancel() {cardListener.setValue({isOpen: false})}, 
            handlerButtonOk() {
                cardListener.setValue({isOpen: false})
                loadDataListeners(txtSearch.value)
            }
        })
    }
    /** Открытие/закрытие карточки по итоговой работе */
    const handleOpenCardFinalExamination = (isOpen: boolean, finalExaminationID?: number, fullNameFrom?: string) => {
        cardFinalExamination.setValue({
            isOpen, cardID: finalExaminationID, fullNameFrom,
            handlerButtonCancel() {cardFinalExamination.setValue({isOpen: false})}, 
            handlerButtonOk() {
                cardFinalExamination.setValue({isOpen: false})
                loadDataListeners(txtSearch.value)
            }
        })
    }
    /** Открытие/закрытие архива итоговых работ слушателей */
    const handleOpenFinalExaminationArchive = (isOpen: boolean) => {
        finalExaminationArchive.setValue({
            isOpen,
            handlerButtonCancel() {finalExaminationArchive.setValue({isOpen: false})}, 
            handlerButtonOk() {
                loadDataListeners(txtSearch.value)
            }
        })
    }
    /** Открытие/закрытие окна проверок итоговых работ слушателей */
    const handleOpenFinalExaminationJobs = (isOpen: boolean) => {
        if (finalExaminationState.countUnverifiedWorks === 0) return notify && notifyOpen('Работы для проверки отсутствуют.', 'info', 1500, notify)

        finalExaminationJobs.setValue({
            isOpen,
            handlerButtonCancel() {finalExaminationJobs.setValue({isOpen: false})}, 
            handlerButtonOk(lastName) {
                txtSearchOnChange(lastName)
                finalExaminationJobs.setValue({isOpen: false})
            }
        })
    }
    /** Открытие/закрытие окна список работ на печать */
    const handleOpenFinalExaminationPrintings = (isOpen: boolean) => {
        finalExaminationPrintings.setValue({
            isOpen,
            handlerButtonCancel() { finalExaminationPrintings.setValue({ isOpen: false }) },
            handlerButtonOk() {
                loadDataListeners(txtSearch.value)
            }
        })
    }

    const debounceSearch = useDebounce(loadDataListeners, 500) // Запуск загрузки заявок через полсекунды
    const txtSearchOnChange = (value: string) => {txtSearch.setValue(value); debounceSearch(value)} // Поле поиска

    /** Свойства передаваемые в компоненту */
    const propsToComponent: IFinalExamination = {
        isLoading: finalExaminationState.listeners.isLoading,
        txtSearch, txtSearchOnChange, loadDataListeners,

        archiveCount: finalExaminationState.listeners.archiveCount,
        countUnverifiedWorks: finalExaminationState.countUnverifiedWorks,
        countNewPrintDiplom: finalExaminationState.countNewPrintDiplom,

        loadingTitle, currentPageNumber, 
        totalCount: finalExaminationState.listeners.totalCount, 
        pageSize: finalExaminationState.listeners.pageSize,

        listenersList: finalExaminationState.listeners.items,

        handlerOpenModalConfirm, 
        handleOpenCardContract,
        handleOpenCardListener,
        handleOpenCardFinalExamination, 
        handleOpenFinalExaminationArchive,
        handleOpenFinalExaminationJobs,
        handleOpenFinalExaminationPrintings,
    }
    
    return <>
        <FinalExamination {...propsToComponent} />

        <ModalConfirm {...modalConfirm.value} />

        <CardListenerContainer {...cardListener.value} />
        <CardContractContainer {...cardContract.value} />
        <CardFinalExaminationContainer {...cardFinalExamination.value} />

        <FinalExaminationJobsContainer {...finalExaminationJobs.value} />
        <FinalExaminationArchiveContainer {...finalExaminationArchive.value} />
        <FinalExaminationPrintingsContainer {...finalExaminationPrintings.value} />
    </>
}