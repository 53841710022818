import { IDelivery } from "./_types"
import CommonService from "services/common.service"

import imgEmty from 'images/empty.svg'
import { IconClearClose, IconDelivery, IconDiplom, IconFilter, IconInfo, IconJobs, IconLocation, IconOutsideMoodle, IconPostPayment, IconScanFile, IconSearch, IconUpdate } from "images/icons"

import { Empty, Flex, Grid, ToolTip, Pagination, Loader, Input, Divider, Button, Table } from "components/ui"


/** Страница доставка */
export const Delivery = ({
    isOpenFilter, filterIsActivate, isLoading, totalCount, pageSize, currentPageNumber, txtSearch, deliveryList,
    loadDataContracts, txtSearchOnChange, handleApplyClearFilter, handleOpenCardListener, handleOpenCardContract, handleOpenCardDelivery
}: IDelivery) => {
    const formatter = new Intl.NumberFormat('ru-RU')

    return <>
        <Flex gap="3px" className="page_container">
            <Loader isOpen={isLoading} backdrop color="#f34e2e" title="Загрузка данных..." />

            <Flex gap="10px" direction="row" justifyContent="space-between" alignItems="center" className="page_container-header">
                <Grid alignItems="center" gap="6px" gridTemplate="columns" gridTemplateColumns="auto 1fr" className="page_container-header-title">
                    <IconDelivery width={28} height={28} />
                    <h4>Доставка ({totalCount})</h4>
                </Grid>

                <Flex gap="6px" direction="row" className="page_header_for_mobile">
                    <Input size="sm" placeholder="Поиск..." paddingLeft={6} width='100%'
                        value={txtSearch.value} onChange={(e) => txtSearchOnChange(e.target.value)}
                        paddingRight={4} prefix={<IconSearch width={16} height={16} />}
                        suffix={<>
                            {txtSearch.value && <Button id='tooltipBtnClear' size="xs" variant="icon" onClick={() => txtSearchOnChange('')}><IconClearClose width={18} height={18} /></Button>}
                            <Button id='tooltipBtnSearchInfo' size="xs" variant="icon"><IconInfo width={18} height={18} /></Button>
                        </>}
                    />
                    <div style={{ position: 'relative' }}>
                        <Button id='tooltipBtnFilter' size="sm" variant="icon" onClick={() => isOpenFilter.setValue(true)}><IconFilter /></Button>
                        <div style={{ position: 'absolute', bottom: '-4px', right: '-4px' }}>
                            {filterIsActivate.value && <Button id='tooltipBtnClear' size="xs" variant="primary" onClick={() => handleApplyClearFilter('clear')}>
                                <IconClearClose width={16} height={16} />
                            </Button>}
                        </div>
                    </div>

                    <Divider type="vertical" margin="3px" />
                    <Button id='tooltipBtnUpdate' size="sm" variant="icon" onClick={() => loadDataContracts(txtSearch.value)}><IconUpdate /></Button>
                </Flex>
            </Flex>

            <Divider variant="dark" />

            <Table.Container loadingTitle="Загрузка...">
                <Table.Header id='hiddenForMobile' classGridTemplateColumns="contracts_grid_columns" marginLeft="3px" marginRight="14px">
                    <span className='table_column_data_center'>Номер заявки</span>
                    <span className='table_column_data_center'>Номер договора<br />Дата договора</span>
                    <span>Тип / Вид договора<br />Контрагент</span>
                    <span className="hiddenFor750">Статус договора<br />Общая сумма / Долг</span>
                    <span className="hiddenFor950 table_column_data_center">Начало обуч.<br/>Конец обуч.</span>
                </Table.Header>

                <Table.Body>
                    {!deliveryList.length
                        ? <Empty image={imgEmty} />
                        : deliveryList.map((item) => (
                            <Table.RowCard key={item.contractID} backgroundColor={item.isArchive === 0 ? item.contractStatusColor : '#303B44'} 
                                classGridTemplateColumnsForHeader="contracts_grid_columns" classGridTemplateColumnsForFooter='contracts_grid_columns_footer'
                                onClick={() => handleOpenCardDelivery(true, item.contractID)}
                                childrenHeader={<>
                                    <p className='table_column_data_center'>{item.applicationID}</p>

                                    <div className='table_column_data_center' style={{ textWrap: 'nowrap', overflow: 'hidden' }}>
                                        <p>{item.contractNumber}</p>
                                        <p style={{ opacity: '.8' }}>{item.contractDate}</p>
                                    </div>

                                    <div style={{ textWrap: 'nowrap', overflow: 'hidden' }}>
                                        <p>{item.companyName && '🔥'} {item.contractTypeName} / {item.contractViewName}</p>
                                        <p style={{ opacity: '.8' }}>{item.companyName || '----'}</p>
                                    </div>

                                    <div className='hiddenFor750' style={{ textWrap: 'nowrap', overflow: 'hidden' }}>
                                        <p>{item.contractStatusName}</p>
                                        <div style={{ opacity: '.8' }}>
                                            <span>{formatter.format(Number(item.contractSumm))} ₽</span> 👉 <span style={{fontWeight: '700', color: `${Number(item.contractPayment) > 0 && 'red'}`}}>{formatter.format(Number(item.contractPayment))} ₽</span>
                                        </div>
                                    </div>

                                    <span className="table_column_data_center hiddenFor950">{item.startEducationDate}<br />{item.endEducationDate}</span>
                                </>}

                                paddingFooter='0 6px' childrenFooter={
                                    <Flex gap="8px" direction="row" justifyContent="space-between">
                                        <p>{item.siteName}</p>

                                        <Flex gap="8px" direction="row">
                                            <span id='tooltipBtnAddress' style={{ cursor: 'help' }}><IconLocation width={18} height={18} color={item.isAddressConfirmed === 1 ? 'green' : 'red'} /></span>
                                            <Divider type="vertical" variant="light" size="15px" />
                                            <span id='tooltipBtnOutsideMoodle' style={{ cursor: 'help' }}><IconOutsideMoodle width={18} height={18} color={item.isLearningOutsideMoodle === 1 ? 'green' : 'red'} /></span>
                                            <span id='tooltipBtnPostPayment' style={{ cursor: 'help' }}><IconPostPayment width={18} height={18} color={item.isPostPayment === 1 ? 'green' : 'red'} /></span>
                                        </Flex>
                                    </Flex>
                                }>

                                {item.listenersList.length === 0
                                    ? <Empty image={imgEmty} size='60px' title='Слушатели отсутствуют' color='#fff' />
                                    : item.listenersList.map((listener, index) => (
                                        <Table.Row key={index} paddingInset='0px 0px 0px 8px'
                                            // badge={(item.contractCount > 0 || item.applicationStatusID === 3) && <p className="table_badge" style={{ background: item.contractCount === 0 ? 'red' : item.applicationStatusColor }}>{item.contractCount}</p>}
                                            onClick={(e) => { handleOpenCardListener(true, listener.listenerID); e.stopPropagation() }}
                                            classGridTemplateColumns="contracts_grid_columns_listeners">
                                            <div style={{ textWrap: 'nowrap', overflow: 'hidden' }}>
                                                <p>{listener.lastName} {listener.firstName} {listener.secondName}</p>
                                                <p style={{ opacity: '.8' }}>{listener.documentTypeName} / {listener.attestationTypeName} / {listener.phoneNumber}</p>
                                            </div>

                                            <div className='hiddenForMobile' style={{ textWrap: 'nowrap', overflow: 'hidden' }}>
                                                <p>{listener.courseName}</p>
                                                <p style={{ opacity: '.8' }}>{CommonService.formatHours(Number(listener.volumeHours))} / {listener.dateEducationStart} / {listener.dateEducationEnd}</p>
                                            </div>

                                            <nav id='hiddenFor750'>
                                                <span id='tooltipBtnJob' style={{ cursor: 'help' }}><IconJobs width={22} height={22} color={listener.isFinalExamination === 1 ? 'green' : 'red'} /></span>
                                                <span id='tooltipBtnPrint' style={{ cursor: 'help' }}><IconDiplom width={22} height={22} color={!listener.printStatusID ? 'red' : listener.printStatusID === 1 ? '#b2b200' : 'green'} /></span>
                                                <span id='tooltipBtnDeliv' style={{ cursor: 'help' }}><IconDelivery width={22} height={22} color={listener.isDelivery === 1 ? 'green' : 'red'} /></span>
                                                <span id='tooltipBtnScan' style={{ cursor: 'help' }}><IconScanFile width={22} color={listener.isScan === 1 ? 'green' : 'red'} /></span>
                                            </nav>
                                        </Table.Row>
                                    ))
                                }
                            </Table.RowCard>
                        ))
                    }
                </Table.Body>
            </Table.Container>

            <Flex direction="row" justifyContent="space-between" alignItems="center" className="page_container-footer">
                <Flex gap="6px" direction="row" alignItems="center">
                    <div style={{width: '25px', height: '25px', borderRadius: '1000px', background: '#303B44'}}></div>
                    <span>В архиве</span>
                </Flex>
                
                <Pagination totalCount={totalCount} pageSize={pageSize} currentPage={currentPageNumber.value} onChange={(e) => currentPageNumber.setValue(e)} />
            </Flex>
        </Flex>

        <ToolTip anchorSelect="#tooltipBtnSearchInfo" place="bottom-end">Поиск по:<br />🗸 Номеру договора<br />🗸 Номеру заявки<br />🗸 Фамилии клиента<br />🗸 Имени клиента<br />🗸 Отчеству клиента<br />🗸 Телефону клиента<br />🗸 Email клиента<br />🗸 Наименованию контрагента<br />🗸 Адресу доставки</ToolTip>
        <ToolTip anchorSelect="#tooltipBtnFilter" content='Фильтр' place="bottom-end" />
        <ToolTip anchorSelect="#tooltipBtnUpdate" content='Обновить список' place="bottom-end" />
        <ToolTip anchorSelect="#tooltipBtnCreate" content='Создать договор' place="bottom-end" />
        <ToolTip anchorSelect="#tooltipBtnClear" content='Очистить' place="bottom-end" />

        <ToolTip anchorSelect="#tooltipBtnJob" content='Итоговая работа' place="bottom-end" />
        <ToolTip anchorSelect="#tooltipBtnPrint" content='Печать' place="top-end" />
        <ToolTip anchorSelect="#tooltipBtnDeliv" content='Доставка' place="bottom-end" />
        <ToolTip anchorSelect="#tooltipBtnScan" content='Скан' place="top-end" />

        <ToolTip anchorSelect="#tooltipBtnAddress" content='Подтверждение адреса' place="bottom-end" />
        <ToolTip anchorSelect="#tooltipBtnOutsideMoodle" content='Обучение вне moodle' place="bottom-end" />
        <ToolTip anchorSelect="#tooltipBtnPostPayment" content='Пост оплата' place="bottom-end" />
    </>
}