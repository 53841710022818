import { AxiosError, AxiosResponse } from "axios"

import api from "../../services/api.service"
import { IResponseBadgeCRM, IResponseSitesDbName } from "./common.types"


const route = '/common'

/** Получение информации по количеству тех или иных данных, на которое стоит обратить внимание BADGE */
export const getCountInfoApp = async () => {
    let response: IResponseBadgeCRM

    try {
        const res: AxiosResponse<IResponseBadgeCRM, AxiosError> = await api.get(`${route}/getCountInfoApp`)

        if (res.statusText !== 'ERR_NETWORK') {
            response = res.data
        } else {
            response = {
                status: 500, 
                message: 'Сервер временно не доступен.',
                errors: null
            }
        }

        return response
    } catch(error) {
        if ((error as AxiosError).code === 'ERR_NETWORK') {response = {status: 500, message: 'Сервер не доступен', errors: (error as AxiosError).code}; return response}

        const resError = (error as AxiosError).response?.data as IResponseBadgeCRM
        response = {status: 500, message: resError?.message, errors: resError.errors}
        return response
    }
}
/** Получение - Даты начала обучения и конец обучения */
export const getEducationStartEndDate = async (startDate?: string, countMonthOrWeek?: number, isWeek?: number) => {
    let response: IResponseSitesDbName

    try {
        const res: AxiosResponse<IResponseSitesDbName, AxiosError> = await api.get(`${route}/getEducationStartEndDate`, {params: {startDate, countMonthOrWeek, isWeek}})

        if (res.statusText !== 'ERR_NETWORK') {
            response = res.data
        } else {
            response = {
                status: 500, 
                message: 'Сервер временно не доступен.',
                errors: null
            }
        }

        return response
    } catch(error) {
        if ((error as AxiosError).code === 'ERR_NETWORK') {response = {status: 500, message: 'Сервер не доступен', errors: (error as AxiosError).code}; return response}

        const resError = (error as AxiosError).response?.data as IResponseSitesDbName
        response = {status: 500, message: resError?.message, errors: resError.errors}
        return response
    }
}